import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TextInput,
  TouchableOpacity,
  AsyncStorage,
  ImageBackground,
  Linking,
} from "react-native";
import { Loading, TextLink } from "./common";
// import { Container, Text } from 'native-base';
import deviceStorage from "../services/deviceStorage";
import axios from "axios";
import { Item, Label, Input, Button } from "native-base";
const io = require("socket.io-client");
const SocketEndpoint = "http://tv.bingo:49156";
class ForgotPass extends Component {
  socket = io(SocketEndpoint, {
    transports: ["websocket"],
  });
  constructor(props) {
    super(props);
    this.state = {
      email: "",
     
      error: "",
      loading: false,
    };
    this.forgotPass = this.forgotPass.bind(this);
  }

  forgotPass() {
    const { email,  } = this.state;

    this.setState({ error: "", loading: true });

  
    axios
      .post("http://tv.bingo:49156/api/player/forgotpass", {
        email: email,
       
      })
      .then((response) => {
        this.setState({ error: response.data.msg ,loading: false,email:''});
      
      })
      .catch((error) => {
       

        this.setState({ error: error.response.data.msg, loading: false });
      });
  }

  render() {
    const { email,error, loading } = this.state;
    const { form, section, errorTextStyle, container } = styles;

    return (
      <View style={styles.container}>
        <ImageBackground
          resizeMode="cover"
          style={styles.img_back}
          source={require("../../assets/img/login-back.png")}
        >
          <View style={styles.logoContainer}>
            {/* <Image
            style={styles.logo}
            source={require("../../assets/img/login.png")}
          /> */}
            {/* <Text style={styles.title}>FNF Couch Bingo</Text> */}
          </View>
          <View style={styles.formContainer}>
            {/* <TextInput style={styles.input}
            placeholder="Email"
            placeholderTextColor="rgba(255,255,255,.7)"
            value={email}
            onChangeText={email => this.setState({ email })}
          /> */}
            <Item style={styles.inpt} floatingLabel>
              <Label style={styles.lbl}>Email</Label>
              <Input
                style={styles.in}
                value={email}
                onChangeText={(email) => this.setState({ email })}
              />
            </Item>

            {/* <TextInput style={styles.input}
            placeholder="Pasword"
            placeholderTextColor="rgba(255,255,255,.7)"
          secureTextEntry
          value={password}
              onChangeText={password => this.setState({ password })}
          /> */}

            <Text style={errorTextStyle}>{error}</Text>
            {!loading ? (
              //  <TouchableOpacity  style={styles.buttonContainer}>
              <Button block onPress={this.forgotPass} style={styles.button}>
                <Text style={styles.buttonText}>Request</Text>
              </Button>
            ) : (
              //  </TouchableOpacity>
              <Loading size={"large"} />
            )}

            <TextLink onPress={this.props.authSwitchForgot}>
              Already have an account? Log in!
            </TextLink>
          </View>
        </ImageBackground>
      </View>
    );
  }
}

const styles = {
  // form: {
  //   width: '100%',
  //   borderTopWidth: 1,
  //   borderColor: '#ddd',
  // },
  // section: {
  //   flexDirection: 'row',
  //   borderBottomWidth: 1,
  //   backgroundColor: '#fff',
  //   borderColor: '#ddd',
  // },
  errorTextStyle: {
    alignSelf: "center",

    fontSize: 18,
    color: "white",
    // marginTop:10,
    marginBottom: 10,
  },
  container: {
    flex: 1,

    // backgroundColor:'#4169e1',
    width: "100%",
    height: "100%",
  },
  logoContainer: {
    // backgroundColor:'red',
    flex: 1,
    alignItems: "center",
    // flexGrow:1,
    justifyContent: "flex-end",
  },
  img_back: {
    flex: 1,
  },
  formContainer: {
    flex: 1,
    alignItems: "center",
    // flexGrow:1,
    justifyContent: "flex-start",
    padding: 20,
  },

  logo: {
    width: 200,
    height: 200,
    // aspectRatio:1
    // aspectRatio: 1
  },
  title: {
    color: "#fff",
    marginTop: 10,
    textAlign: "center",
    fontSize: 15,
    opacity: 0.9,
  },
  input: {
    height: 40,
    backgroundColor: "rgba(255,255,255,0.2)",
    marginBottom: 20,
    color: "#fff",
    paddingLeft: 10,
  },
  buttonContainer: {
    // backgroundColor:'#2980b9',
    // paddingVertical:15
  },
  buttonText: {
    textAlign: "center",
    color: "#B41487",
    fontWeight: "700",
    fontSize: 20,
  },
  button: {
    backgroundColor: "white",
  },
  inpt: {
    borderColor: "white",
    marginBottom: 10,
    color: "white",
  },
  lbl: {
    color: "white",
  },
  in: {
    color: "white",
  },
  link: {
    alignSelf: "center",
    color: "white",
    fontSize: 18,
    // fontWeight: '700',
    textDecorationLine: "underline",
  },
  sep: {
    color: "white",
    fontSize: 18,
    paddingLeft: 10,
    paddingRight: 10,
  },
  linkcon: {
    flex: 1,
    flexDirection: "row",
  },
};

export { ForgotPass };
