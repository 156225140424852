import React, { Component, Fragment, StatusBar } from "react";
import { View, Text, TouchableOpacity, ImageBackground } from "react-native";
import { TextLink, Loading } from "./common";
import axios from "axios";
import {
  Item,
  Label,
  Button,
  Input,
  Container,
  Content,
  Grid,
  Col,
  DatePicker,
} from "native-base";
import DateTimePicker from "@react-native-community/datetimepicker";
class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstname: "",
      lastname: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      dob: "",
      password: "",
      password_repeat: "",
      error: "",
      loading: false,
      dp:false
    };
    this.RegisterUser = this.RegisterUser.bind(this);
    this.validate = this.validate.bind(this);
    this.dateonChange=this.dateonChange.bind(this)
    this.changeDp=this.changeDp.bind(this)
  }

  validate() {
    // return false;
    if (this.state.email == "") {
      this.setState({ error: "Please enter Email Address" });
    } else if (this.state.firstname == "") {
      this.setState({ error: "Please enter First Name" });
    } else if (this.state.lastname == "") {
      this.setState({ error: "Please enter Last Name" });
    } else if (this.state.street == "") {
      this.setState({ error: "Please enter Street" });
    } else if (this.state.city == "") {
      this.setState({ error: "Please enter City" });
    } else if (this.state.state == "") {
      this.setState({ error: "Please enter State" });
    } else if (this.state.zip == "") {
      this.setState({ error: "Please enter Zip" });
    } else if (this.state.phone == "") {
      this.setState({ error: "Please enter Phone" });
    } else if (this.state.dob == "") {
      this.setState({ error: "Please enter Date of Birth" });
    } else if (this.state.password == "") {
      this.setState({ error: "Please enter Password" });
    } else if (this.state.password_repeat == "") {
      this.setState({ error: "Please confirm Password" });
    } else {
      this.RegisterUser();
    }
  }

  RegisterUser() {
    const {
      email,
      firstname,
      lastname,
      street,
      city,
      state,
      zip,
      phone,
      dob,
      password,
      password_repeat,
    } = this.state;

    this.setState({ error: "", loading: true });

    axios
      .post("http://tv.bingo:49156/api/player/sign-up", {
        email: email,
        firstname: firstname,
        lastname: lastname,
        street: street,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        dob: dob,
        password: password,
        password_repeat: password_repeat,
      })
      .then((response) => {
        console.log("response");

        this.setState({
          error: response.data.msg,
          loading: false,

          email: "",
          firstname: "",
          lastname: "",
          street: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
          dob: "",
          password: "",
          password_repeat: "",
        });
        // Handle the JWT response here
      })
      .catch((error) => {
        console.log(error.response.data.msg);
        this.setState({ error: error.response.data.msg, loading: false });
        // Handle returned errors here
      });
  }
 
  changeDp(){
     this.setState({ dp:true })
  }
  dateonChange = (event, selectedDate) => {
   
    let date=new Date(selectedDate)

    date = date.getUTCFullYear() + '-' +
    ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
    ('00' + date.getUTCDate()).slice(-2) 
 

   this.setState({dob:date,dp:false})
  };
  render() {
    const {
      email,
      firstname,
      lastname,
      street,
      city,
      state,
      zip,
      phone,
      dob,
      password,
      password_repeat,
      error,
      loading,
    } = this.state;
    const { form, section, errorText } = styles;
    return (
      <ImageBackground style={styles.img_back}  source={require("../../assets/img/reg-back.png")}>
      <Container style={styles.container}>
        
        <View style={styles.formContainer}>
          <View>
            <Item style={styles.inpt} floatingLabel>
              <Label style={styles.lbl}>Email</Label>
             <Input style={styles.in}
                value={email}
                onChangeText={(email) => this.setState({ email })}
              />
            </Item>
          </View>

          <View style={styles.rw}>
            <View style={[styles.cl, styles.left]}>
              <Item style={styles.inpt} floatingLabel>
                <Label style={styles.lbl}>First Name</Label>
               <Input style={styles.in}
                  value={firstname}
                  onChangeText={(firstname) => this.setState({ firstname })}
                />
              </Item>
            </View>
            <View style={[styles.cl, styles.right]}>
              <Item style={styles.inpt} floatingLabel>
                <Label style={styles.lbl}>Last Name</Label>
               <Input style={styles.in}
                  value={lastname}
                  onChangeText={(lastname) => this.setState({ lastname })}
                />
              </Item>
            </View>
          </View>
          <View style={styles.rw}>
            <View style={[styles.cl, styles.left]}>
              <Item style={styles.inpt} floatingLabel>
                <Label style={styles.lbl}>Street</Label>
               <Input style={styles.in}
                  value={street}
                  onChangeText={(street) => this.setState({ street })}
                />
              </Item>
            </View>
            <View style={[styles.cl, styles.right]}>
              <Item style={styles.inpt} floatingLabel>
                <Label style={styles.lbl}>City</Label>
               <Input style={styles.in}
                  value={city}
                  onChangeText={(city) => this.setState({ city })}
                />
              </Item>
            </View>
          </View>

          <View style={styles.rw}>
            <View style={[styles.cl, styles.left]}>
              <Item style={styles.inpt} floatingLabel>
                <Label style={styles.lbl}>State</Label>
               <Input style={styles.in}
                  value={state}
                  onChangeText={(state) => this.setState({ state })}
                />
              </Item>
            </View>

            <View style={[styles.cl, styles.right]}>
              <Item style={styles.inpt} floatingLabel>
                <Label style={styles.lbl}>Zip</Label>
             
               <Input style={styles.in}
                  value={zip}
                  onChangeText={(zip) => this.setState({ zip })}
                />
              </Item>
            </View>
          </View>
          <View style={styles.rw}>
            <View style={[styles.cl, styles.left]}>
       
              <Item  style={styles.inpt} floatingLabel>
                <Label style={styles.lbl}>D. O. B.</Label>
               
               <Input style={styles.in}
                  value={dob}
                 onFocus={this.changeDp}
                />
                   
              </Item>
            
             
        {this.state.dp && (    <DateTimePicker
                  testID="dateTimePicker"
                  timeZoneOffsetInMinutes={0}
                  value={new Date()}
                  mode="date"
                  is24Hour={false}
                  display="default"
                  onChange={this.dateonChange}
                  locale="es-ES"
                />)}
            </View>

            <View style={[styles.cl, styles.right]}>
              <Item style={styles.inpt} floatingLabel>
                <Label style={styles.lbl}>Phone</Label>
               <Input style={styles.in}
                  value={phone}
                  onChangeText={(phone) => this.setState({ phone })}
                />
              </Item>
            </View>
          </View>

          <View style={styles.rw}>
            <View style={[styles.cl, styles.left]}>
              <Item style={styles.inpt} floatingLabel>
                <Label style={styles.lbl}>Password</Label>
               <Input style={styles.in}
                  secureTextEntry
                  value={password}
                  onChangeText={(password) => this.setState({ password })}
                />
              </Item>
            </View>
            <View style={[styles.cl, styles.right]}>
              <Item style={styles.inpt} floatingLabel>
                <Label style={styles.lbl}>Confirm Password</Label>
               <Input style={styles.in}
                  secureTextEntry
                  value={password_repeat}
                  onChangeText={(password_repeat) =>
                    this.setState({ password_repeat })
                  }
                />
              </Item>
            </View>
          </View>
                  <Text style={styles.title}>This information is used as a means of awarding you a prize andverification of identity for cliaming a prize.</Text>
          <Text style={styles.errorTextStyle}>{error}</Text>

          {!loading ? (
            <Button block style={styles.button} onPress={this.validate}>
              <Text style={styles.buttonText}>Register</Text>
            </Button>
          ) : (
            <Loading size={"large"} />
          )}

          <TextLink onPress={this.props.authSwitch}>
            Already have an account? Log in!
          </TextLink>
        </View>
     
      
      </Container>
      </ImageBackground>
    );
  }
}

const styles = {
  rw: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cl: {
    width: "50%",
  },
  left: {
    paddingRight: 5,
  },
  right: {
    paddingLeft: 5,
  },
  errorTextStyle: {
    alignSelf: "center",
    fontSize: 18,
    color: "white",
    marginBottom: 10,
  },
  container: {
    flex: 1,
    justifyContent: "center",
      
    backgroundColor:'transparent',
    width: "100%",
    height: "100%",
  },

  formContainer: {
    // backgroundColor: "yellow",
    // flex:1,
    // display:'flex',
    // flexDirection:'row',
    // alignItems: "center",
    // // // flexGrow:1,
    // justifyContent: "space-around",
    padding: 20,
  },

  logo: {
    width: 200,
    height: 200,
    // aspectRatio:1
    // aspectRatio: 1
  },
  title: {
    color:'#fff',
    marginTop: 10,
    textAlign: "center",
    opacity: 0.9,


    
  },
  input: {
    height: 40,
    backgroundColor: "rgba(255,255,255,0.2)",
    // marginBottom: 20,
    color: "#fff",
    paddingLeft: 10,
  },
  buttonContainer: {
    // backgroundColor:'#2980b9',
    // paddingVertical:15
  },
  buttonText: {
    textAlign: "center",
    color: '#B41487',
    fontWeight:'700'
    ,fontSize:20
  },
  button: {
    backgroundColor: "white",
    // borderColorLwhite:'white'
  },
  inpt: {
    borderColor:'white',
    marginBottom:10,
    color:'white'
  },
  img_back:{
    flex: 1,
  },
  lbl:{
    color:'white'
  }
  ,in:{
    color:'white'
  }
};
export { Registration };
