import React, { Component, Fragment } from "react";
import {
  View,
  Text,
  AsyncStorage,
  TouchableHighlight,
  TouchableOpacity,
} from "react-native";
import { Loading } from "../components/common/";
import Modal from "react-native-modal";
import { Hostitem } from "../components/Hostitem";
// import Prompt from "react-native-prompt";
import {
  List,
  ListItem,
  Left,
  Thumbnail,
  Body,
  Right,
  Button,
  Form,
  Item,
  Label,
  Input,
} from "native-base";

const io = require("socket.io-client");
const SocketEndpoint = "http://tv.bingo:49156";
class Hostlist extends Component {
  socket = io(SocketEndpoint, {
    transports: ["websocket"],
  });
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      temhost: "",
      hosts: [],
      user: [],
      visible: false,
      rand: this.makeid(5),
      password: "",
      err: "",
    };

    // this.selectHost = this.selectHost.bind(this);
  }
  selectHost(nickname, gameno, drawn, pin) {
    if (gameno > 5 && drawn >= 1) {
      alert(
        "Sorry," + gameno + "th game has already begun. No new players allowed."
      );
    } else if(gameno > 6){
      alert(
        "Sorry," + gameno + "th game has already begun. No new players allowed."
      );
    }
    
    
    else {
      if (drawn > 3) {
        alert("HOLD! Host is midgame on game #" + gameno + ". Please Wait.");
      } else {
        if (pin != "") {
          this.setState({ temhost: nickname, visible: true, pin: pin });
        } else {
          this.props.setChannel(nickname);
        }

        //
      }
    }
  }
  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  componentWillUnmount() {
    this.socket.disconnect();
  }

  async componentDidMount() {
    try {
      const myArray = await AsyncStorage.getItem("user");
      if (myArray !== null) {
        // We have data!!
        this.setState({ user: JSON.parse(myArray) });
        console.log(this.state.user);
      }
    } catch (error) {
      // Error retrieving data
    }

    this.socket.on("connect", () => {
      //here no need to join channel

      this.socket.emit("requestonline", {
        device: "app",
        user_id: this.state.user.id,
      });
      this.socket.emit("logoff", {
        rand: this.state.rand,
        user_id: this.state.user.id,
      });
    });

    this.socket.on("logoff_", (data) => {
      //here no need to join channel
      if (this.state.user.id == data.user_id && data.rand != this.state.rand) {
        alert("you are going to logoff ");
        this.props.deleteJWT();
      }
    });

    this.socket.on("disconnect", () => {
      this.setState({ isConnected: false });
    });

    this.socket.on("imonline_", (data) => {
      // console.log('=============================imonline_==============================')
      // console.log(data)
      // alert(data.nickname)
      this.setState({ loading: false });
      var array = this.state.hosts;

      var exist = false;
      var BreakException= {};

      try {
        array.forEach((element) => {
          // alert(element.nickname+'old')
          // alert(data.nickname+'new')
          if (element.nickname === data.nickname) {
            element.status = data.status;
            element.gameno = data.gameno;
            element.drawn = data.drawn;
            element.color = data.color;
            element.online = data.online;
            element.avatar = data.avatar;
            element.pin = data.pin;
            exist = true;
            throw BreakException;
          
          }
        });
    } catch(e) {
        if (e!==BreakException) throw e;
    }


    

      if (!exist) {
        if (data.nickname) {
          array = array.concat(data);
        }
      }

      this.setState({ hosts: [] });
      this.setState({ hosts: array });
      
      console.log('------------------------------------------------------------------------');
      
      console.log(this.state.hosts);
    });

    const headers = {
      Authorization: "Bearer " + this.props.jwt,
    };
    // axios({
    //   method: "GET",
    //   url: "http://tv.bingo:49156/api/player/get-hosts",
    //   headers: headers,
    // })
    //   .then((response) => {
    //     // console.log(response);
    //     this.setState({
    //       hosts: response.data.hosts,
    //       loading: false,
    //     });
    //   })
    //   .catch((error) => {
    //     this.setState({
    //       error: "Error retrieving data",
    //       loading: false,
    //     });
    //   });
  }
  _renderButton = (text, onPress) => (
    <Button block style={styles.button} onPress={onPress}>
      <Text style={styles.buttonText}>{text}</Text>
    </Button>
  );
  _renderModalContent = () => (
    <View style={styles.modalContent}>
      <Item style={styles.inpt} floatingLabel>
        <Label style={styles.lbl}>Please Enter Password to Join</Label>
        <Input
          style={styles.in}
          secureTextEntry
          value={this.state.password}
          onChangeText={(password) => this.setState({ password })}
        />
      </Item>
      <Text>{this.state.err}</Text>
      {this._renderButton("Join", () => {
        if ((this.state.password == this.state.pin)) {
          this.setState({ visible: false });
          this.props.setChannel(this.state.temhost);
        } else {
          this.setState({ err: "Invalid Password" });
        }
      })}
    </View>
  );
  render() {
    const { container, emailText, errorText } = styles;
    const { loading, hosts } = this.state;
   
    if (loading) {
      return (
        <View style={container}>
          <Loading size={"large"} />
        </View>
      );
    } else {
      var nickarr=[]
      const listItems = hosts.map((host) => {
     
        if (host.online == 1 && !nickarr.includes(host.nickname)) {
          nickarr.push(host.nickname)
          return (
            <Hostitem
              method={this.selectHost.bind(
                this,
                host.nickname,
                host.gameno,
                host.drawn,
                host.pin
              )}
              key={host.nickname}
              nickname={host.nickname}
              status={host.status}
              color={host.color}
              avatar={host.avatar}
            />
          );
        }
      });
     
      return (
        <Fragment>
          <Modal isVisible={this.state.visible}
           onBackdropPress={() => this.setState({visible: false})}>
          
       
            {this._renderModalContent()}
          </Modal>
          <List>{listItems}</List>

          {/* <WebView source={{ uri: 'https://youtu.be/UqCWx_MZvZ4' }} style={{ marginTop: 20 
        
        
        
        }} 
        allowsInlineMediaPlayback
        
        /> */}
        </Fragment>
      );
    }
  }
}

const styles = {
  modalContent: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  buttonText: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
    fontSize: 20,
  },
  button: {
    marginTop: 20,
    backgroundColor: "#B41487",
    // borderColorLwhite:'white'
  },
};

export { Hostlist };
