import React, { Component } from "react";
import {
  Text,
  View,
  Dimensions,
  Image,
  TouchableOpacity,
  ImageBackground,
} from "react-native";

class WinCardCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }

  render() {
    const cardnums = this.props.pattern.map((ele, index) => (
      <View style={{ width: "20%" }}>
        <View style={styles.cardBoxStyle}>
          <Image
            source={require("../../assets/img/brush.png")}
            style={ele.iswin ? styles.active : styles.inactive}
          />

          <Text style={[ele.iswin?styles.activeText:styles.aaa,index==12?styles.middlenum:styles.num]}>{ele.num}</Text>
        </View>
      </View>
    ));
    return (
      <View style={styles.wcard}>
        <View style={{width:'90%'}}>
            <ImageBackground
              source={require("../../assets/img/cardback.png")}
              style={styles.cardback}
            >

            <View style={{ width: "100%", aspectRatio: 5 }}>
              <View style={styles.boxheadcontainer}>
                <Image
                  style={{
                    width: "100%",
                    height: "100%",
                    resizeMode: "contain",
                    zIndex: -1,
                   
                    
                  }}
                  source={require("../../assets/img/header.png")}
                />
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              {cardnums}
            </View>
            </ImageBackground>
            </View>
      </View>
    );
  }
}

const styles = {
  aaa:{},
  cardback: {
    resizeMode: "cover",
    width: '100%',
   
  },
  wcard: {
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    position: "absolute",
    zIndex: 10000,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  boxheadcontainer: {
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
  },
  cardBoxStyle: {
    width: "100%",

    aspectRatio: 1,
    borderWidth: 1,
    // backgroundColor: "white",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    resizeMode: "contain", // or 'stretch'
    position: "absolute",
    width: "100%",
    // height: '100%',
    // aspectRatio:1,
    // backgroundColor: activecolor,
    // borderRadius: 25,
    // position:'absolute'
  },
  activeText: {
    fontSize: 40,
    fontWeight: "700",
    zIndex: 1,
    color: "white",
  },
  inactive: {
    display: "none",
  },
  // cardback: {
  //   resizeMode: "cover",
  //   width: "100%",
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   height: "100%",
  // },
  num: {
    fontSize: 40,
    fontWeight: "700",
    zIndex: 1,

    
   
  },
  middlenum: {
    fontSize: 20,
    // color: "white",
  },
};

export { WinCardCon };
