import React, { Component, Fragment } from "react";
import { Text, View, Dimensions, Image ,TouchableOpacity,ImageBackground} from "react-native";
import { Cardnum } from "./Cardnum";
import { Audio } from "expo-av";
import { Button } from "./common";

const screenWidth = Math.round(Dimensions.get("window").width);
class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: this.props.card,

      active: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      shades:{1:[],2:[],3:[],4:[0,4,20,24],5:[0,1,2,3,4,5,10,15,20,21,22,23,24],6:[],7:[],8:[],9:[2,7,10,11,13,14,17,22],10:[]},
      shadesSecondary:{1:[],2:[],3:[],4:[6,8,16,18],5:[],6:[],7:[],8:[],9:[],10:[]}
    };
    console.log(this.props.test);
    console.log(this.props.allnumbers);

    this.clickNum = this.clickNum.bind(this);
  }
  async clickNum(index, value) {
    const soundObject = new Audio.Sound();

    if (this.props.allnumbers.includes(value)) {
      var tempact = this.state.active.slice();
      tempact[index] = true;
      this.setState({ active: tempact });

      try {
        await soundObject.loadAsync(require("../../assets/sounds/ok.wav"));
        await soundObject.playAsync();
      } catch { }
    } else {
      try {
        await soundObject.loadAsync(require("../../assets/sounds/no.wav"));
        await soundObject.playAsync();
      } catch { }
    }
  }
  cardHeadStyle = function (params) {
    return {
      backgroundColor: this.props.red,
      width: "20%",
      aspectRatio: 1,
    };
  };
  cardBoxStyle = function (params) {
    return {
      // backgroundColor: ""this.props.bingocardback"",
      borderColor: this.props.bingocardback,
      width: "20%",
      aspectRatio: 1,
      borderWidth: 1,
    };
  };
  render() {
    const { card } = this.state;
    const cardnums = card.map((ele, index) => (
      <Cardnum
        key={ele.key}
        active={this.state.active[index]}
        key_={ele.key}
        value_={ele.value}
        method={this.clickNum.bind(this, ele.key - 1, ele.value)}
        bingocardback={this.props.bingocardback}
        cardBoxStyle={this.cardBoxStyle()}
        isShade={this.state.shades[this.props.gameno].includes(index)}
        isShadeSecondary={this.state.shadesSecondary[this.props.gameno].includes(index)}
      />
    ));

    return (
      <Fragment>
           {/* <ImageBackground
            source={require("../../assets/img/cardback.png")}
            style={styles.cardback}
          > */}
       
        <View style={{ width: '100%', aspectRatio: 5 }}>
       
          <View style={styles.boxheadcontainer}>
            <Image
              style={{ width: "100%", height:'100%',resizeMode: "contain", zIndex:-1,borderWidth:1,borderColor:this.props.bingocardback}}
              source={require("../../assets/img/header.png")}
            />
        
           
          </View>
        </View>
              {cardnums}

              {/* </ImageBackground> */}


      </Fragment>
    );
  }
}

const styles = {
  numback: {},
  boxhead: {
    backgroundColor: "#e8e8e8",
    width: "20%",
    aspectRatio: 1,
  },
  boxheadcontainer: {
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  boxheadtitle: {
    color: "#B41487",
    fontSize: 65,
    fontWeight: "bold",
  },
  cardback: {
    resizeMode: "contain",
    width: "100%",
  },
};

export { Card };
