import React from "react";
import { View, ActivityIndicator } from "react-native";
import Dialog, { DialogContent, DialogTitle } from "react-native-popup-dialog";
import { Text } from "native-base";

const Alert = ({ msg,show,setAlertStatus,videoon }) => {
  return (
 
    <Dialog 
    width='90%'
      visible={show}
      onTouchOutside={() => {
        // this.setState({ visible: false });
        setAlertStatus()
      }}
      dialogTitle={<DialogTitle textStyle={styles.pophead} style={styles.header} title="Alert" />}
    >
      <DialogContent>
        <Text style={{ fontSize: 25,textAlign:'center' }}>{msg}</Text>
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  header:{
    backgroundColor:'#B41487',
    color:'white'
  },
  pophead:{color:'white',fontSize:30},
};
export { Alert };
