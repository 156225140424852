import React, { Component } from "react";
// import { View, Text } from "react-native";

import {
  Container,
  Header,
  Title,
  Content,
  Footer,
  FooterTab,
  Button,
  Left,
  Right,
  Body,
  Icon,
  Text,
  View,
  StyleProvider,
} from "native-base";
// import { Button, Loading } from "../components/common/";
import getTheme from "../../node_modules/native-base/src/theme/components";
import material from "../../node_modules/native-base/src/theme/variables/material";
import { Hostlist } from "../components/Hostlist";
import { Main } from "../components/Main";
import { Alert, ImageBackground } from "react-native";

export default class LoggedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      error: "",
      jwt: this.props.jwt,
      channel: "",
      user: [],
    };
    this.whichForm = this.whichForm.bind(this);
    this.setChannel = this.setChannel.bind(this);
    this.showList = this.showList.bind(this);
    this.logout = this.logout.bind(this);
  }
  setChannel(channel) {
    this.setState({
      channel: channel,
    });
  }
  componentDidMount() {
  
   
    console.log(this.props.jwt);
  }
  whichForm() {
    if (this.state.channel == "") {
      return (
        <Hostlist
          setChannel={this.setChannel}
          jwt={this.state.jwt}
          deleteJWT={this.props.deleteJWT}
        />
      );
    } else {
      return <Main channel={this.state.channel}  deleteJWT={this.props.deleteJWT} jwt={this.state.jwt} />;
    }
  }
  showList() {
    if (this.state.channel != "") {
      Alert.alert(
        "Alert",
        "Are you sure you want to go to waiting room?",
        [
          {
            text: "Yes",
            onPress: () => {
              this.setState({
                channel: "",
              });
            },
          },
          { text: "No", onPress: () => {} },
        ],
        { cancelable: false }
      );
    }
  }
  logout() {
    Alert.alert(
      "Alert",
      "Are you sure you want log out?",
      [
        {
          text: "Yes",
          onPress: () => {
            this.props.deleteJWT();
          },
        },
        { text: "No", onPress: () => {} },
      ],
      { cancelable: false }
    );
  }
  render() {
    const { title } = styles;
    const { loading, email, error, hosts } = this.state;

    return (
      // <View style={container}>
      //   {/* <Hostlist jwt={this.state.jwt} /> */}
      //   {this.whichForm()}
      //   <Button onPress={this.props.deleteJWT}>Log Out</Button>
      // </View>
      <StyleProvider style={getTheme(material)}>


        <Container>
        
          <Header>
            <Left>
              <Button onPress={this.showList} transparent>
                <Icon name="menu" />
              </Button>
            </Left>
            <Body>
              <Title style={title}>FNF Couch Bingo</Title>
            </Body>
            <Right>
              <Button onPress={this.logout} transparent>
                <Icon name="log-out" />
              </Button>
            </Right>
          </Header>
          <View style={{ flex: 1 }}>{this.whichForm()}</View>
          {/* <Footer>
          <FooterTab>
            <Button full>
              <Text>Footer</Text>
            </Button>
          </FooterTab>
        </Footer> */}
        </Container>
 </StyleProvider>
    );
  }
}

const styles = {
  title: {
    alignSelf: "center",
  },
  // container: {
  //   flex: 1,
  //   justifyContent: "center",
  //   backgroundColor: "red",
  // },
  // emailText: {
  //   alignSelf: "center",
  //   color: "black",
  //   fontSize: 20,
  // },
  // errorText: {
  //   alignSelf: "center",
  //   fontSize: 18,
  //   color: "red",
  // },
};
