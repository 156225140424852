import React, { Fragment } from "react";
// import Canvas from "react-native-canvas";

import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  ImageBackground,
  Image,
} from "react-native";
import { ListItem, Left, Thumbnail, Body } from "native-base";
const screenWidth = Math.round(Dimensions.get("window").width);

const activecolor = "#B41487";
const activetextcolor = "white";
const Cardnum = ({ key_, value_, method, active, cardBoxStyle,isShade,isShadeSecondary,bingocardback }) => {
  var whichstyle = styles.num;
  if (key_ == "13") {
    whichstyle = [styles.middlenum];
  }

  // handleCanvas = (canvas) => {
  //   const ctx = canvas.getContext("2d");
  //   ctx.beginPath();
  //   // alert(canvas.width/2)
  //   ctx.arc((canvas.width/4), (canvas.width/4), 30, 0, 2 * Math.PI);
  //   ctx.stroke();
    
  // };
  return (
    <Fragment>
      <View style={cardBoxStyle}>
        {/* <Canvas ref={this.handleCanvas}/> */}
        {/* <ImageBackground  style={styles.imageback active?styles.active:''}> */}
        <TouchableOpacity
          disabled={active ? true : false}
          style={[styles.buttonnum,isShade?styles.shade:'',isShadeSecondary?styles.shadeSec:'']}
          onPress={method}
        >
          <View style={active?styles.active:styles.inactive} ></View>
          {/* <Image source={require('../../assets/img/brush.png')} style={active?styles.active:styles.inactive} /> */}
          {/* <View 
          
          style={active?styles.active:styles.inactive} >


          </View> */}

          {/* <Canvas
            ref={handleCanvas}
            style={{ width: "100%", height: "100%", position: "absolute" }}
          /> */}




          <Text
            style={[
              whichstyle,
              active ? styles.activeText : styles.inactiveText,
            ]}
          >
            {value_}
          </Text>

          {/* <Text>{key_}</Text> */}
        </TouchableOpacity>
        {/* </ImageBackground> */}
      </View>
    </Fragment>
  );
};

const styles = {
  active: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    resizeMode: 'contain', // or 'stretch'
    position: "absolute",
    width: '85%',
    height: '85%',
   

    backgroundColor:'#B41487',
    borderRadius:1000,
   
    // height: '100%',
    // aspectRatio:1,
    // backgroundColor: activecolor,
    // borderRadius: 25,
    // position:'absolute'
  },
  activeText: {
    color: "white",
    
  },
  inactive: {
    display: "none",
  },
  shade:{
    backgroundColor: activecolor+20,
   
  },
  shadeSec:{

    backgroundColor:activecolor+40,
  },
  // circle:{
  //   width: boxwidth,
  //   height: boxwidth,
  //   borderRadius: boxwidth/2,
  //   backgroundColor: 'red',
  //   position:'absolute',

  //   shadowColor: '#000',
  //   shadowOffset: { width: 0, height: 2 },
  //   shadowOpacity: 0.8,
  //   shadowRadius: 2,
  //   elevation: 1,
  //   elevation:5,

  //   flex:1,
  //   justifyContent:'center',
  //   alignItems:'center'

  // },

  // active:{
  //   backgroundColor:'green',

  // },
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  box: {
    backgroundColor: "blue",
    borderColor: "#e8e8e8",
    width: "20%",
    aspectRatio: 1,
    // borderWidth: 1,
  },

  buttonnum: {
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  num: {
    fontSize: 40,
    fontWeight: "700",
    zIndex: 1,

    
   
  },
  middlenum: {
    fontSize: 15,
    color: "white",
  },
};
export { Cardnum };
