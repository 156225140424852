import React, { Component, Fragment } from "react";

import {
  Text,
  View,
  Navigator,
  TextInput,
  TouchableOpacity,
  Dimensions,
  StatusBar,
  ImageBackground,
  Modal,
  TouchableHighlight,
  AsyncStorage,
} from "react-native";
import { VideoCon } from "./VideoCon";
import { WinCardCon } from "./WinCardCon";
import { Alert } from "./Alert";
import { Card } from "./Card";
import { Loading } from "./common";
import { Video } from "expo-av";

import {
  Button,
  Content,
  Left,
  Right,
  Body,
  // Header,
  Grid,
  Col,
  Container,
  Footer,
  FooterTab,
  Icon,
} from "native-base";
import Image from "react-native-scalable-image";
// import { Container, Text } from 'native-base';
// import deviceStorage from '../services/deviceStorage';
import axios from "axios";
import { ImageCon } from "./ImageCon";
import deviceStorage from "../services/deviceStorage";
import { Playerinfo } from "./Playerinfo";
import { Audio } from "expo-av";
import toolbarHeight from "../../native-base-theme/variables/platform";
const io = require("socket.io-client");
const SocketEndpoint = "http://tv.bingo:49156";
const screenWidth = Math.round(Dimensions.get("window").width);
const screenHeight = Math.round(Dimensions.get("window").height);

const no75 = new Array();

const bingocardback = "gray";
for (let i = 1; i <= 75; i++) {
  no75.push(i);
}
class Main extends Component {
  socket = io(SocketEndpoint, {
    transports: ["websocket"],
  });

  constructor(props) {
    super(props);
    this.state = {
      channel: this.props.channel,
      loading: true,
      isConnected: false,
      drawnumber: "",
      lastdrawn: "",
      numbersdrawn: "",

      // for video playing
      playVideo: false,
      videourl: "",
      playpause: false,

      //for imageshow
      imageurl: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
      showImage: false,
      //for card numbers
      card: [],
      allnumbers: [],

      visible: false,
      test: "sajith",

      //show alert
      alert: "",
      alertstatus: false,

      // for show all

      isshowall: false,

      //
      user: [],
      playerinfostatus: false,
      gamecolor: "#1f487c",
      gameno: 0,

      imageheight: 0,

      onlinecount: 0,
      calldis: false,
      winCard:false,
      pattern:[],
      gamenames:['Regular','Regular','Regular','Four Corners','C Pattern','Regular','Regular','Regular','Plus Sign','Blackout']
   
    };
    this.showVideoComponent = this.showVideoComponent.bind(this);
    this.showImageComponent = this.showImageComponent.bind(this);
    this.play = this.play.bind(this);
    this.setPlayVideo = this.setPlayVideo.bind(this);
    this.setAlertStatus = this.setAlertStatus.bind(this);
    this.setPlayerInfoStatus = this.setPlayerInfoStatus.bind(this);
    this.playerInfo = this.playerInfo.bind(this);
    this.getCard = this.getCard.bind(this);
    this.showCard = this.showCard.bind(this);
    this.showAll = this.showAll.bind(this);
    this.callBingo = this.callBingo.bind(this);
    this.find_dimesions = this.find_dimesions.bind(this);
    this.setCallDis = this.setCallDis.bind(this);
    this.showWinCard=this.showWinCard.bind(this);
    this.setCardwinState=this.setCardwinState.bind(this)
  } 
  setPlayVideo(playVideo) {
    this.setState({
      playVideo: playVideo,
      playpause:playVideo
    });
  }
  setAlertStatus() {
    this.setState({
      alertstatus: false,
    });
  }
  setPlayerInfoStatus() {
    this.setState({
      playerinfostatus: false,
    });
  }
  componentWillUnmount() {
    this.socket.emit("leave_channel", {
      channel: this.state.channel,
    });
    this.socket.disconnect("data");
  }

  async componentDidMount() {
    try {
      const myArray = await AsyncStorage.getItem("user");
      if (myArray !== null) {
        // We have data!!
        this.setState({ user: JSON.parse(myArray) });
        console.log(this.state.user);
      }
    } catch (error) {
      // Error retrieving data
    }
    // deviceStorage.loadUser()
    this.socket.on("connect", () => {
      this.setState({ isConnected: true });
      this.socket.emit("join_channel", {
        channel: this.state.channel,
        device: "app",
        user_id: this.state.user.id,
      });
    });

    this.socket.on("disconnect", () => {
      this.setState({ isConnected: false });
    });
    this.socket.on("logoff_", (data) => {
      //here no need to join channel
      if (this.state.user.id == data.user_id) {
        alert("you are going to logoff ");
        this.props.deleteJWT();
      }
    });
    this.socket.on("video_", (data) => {
      this.setState({ videourl: data.url });
      this.play(data.status);
      // console.log(data);
    });
    this.socket.on("presentationon_", (data) => {
      // console.log(data);
      this.setState({ showImage: data.status, imageurl: data.url });
    });
    this.socket.on("sendimage_", (data) => {
      // console.log(data);
      this.setState({ imageurl: data.url, showImage: true });
    });
    this.socket.on("sendmsgtoapp_", (data) => {
      // console.log(data);
      this.setState({ alertstatus: true, alert: data.message });
    });
    this.socket.on("numbers_", (data) => {
      this.setState({
        drawnumber: data.pre_drawnumber,
        numbersdrawn: data.numbersdrawn,
        lastdrawn: data.pre_lastdrawnumber,

        allnumbers: data.allnumbers,
        test: "sajith sajith",
      });
    });
    this.socket.on("changegameno_", (data) => {
      // ger card only if game no changes
      if (this.state.gameno != data.gameno) {
        this.getCard(data.gameno);
      }
      this.setState({ gamecolor: data.color, gameno: data.gameno });
    });
    this.socket.on("onlinecount", (data) => {
      this.setState({ onlinecount: data });
    });

    this.socket.on("winner_", (data) => {
      this.setState({ winCard: true,pattern:data.pattern , videourl: data.url});
      setTimeout(this.setCardwinState, 5000);
    });
  }
  setCardwinState(){
    this.setState({ winCard: false });
   
    this.play(1);
  }
  footerStyle = function (params) {
    return {
      backgroundColor: this.state.gamecolor,
      flexDirection: "row",
      justifyContent: "space-around",

      height: 60,
    };
  };

  drawnum = function (params) {
    return { fontSize: this.state.imageheight / 4.5, textAlign: "center" ,padding:0};
  };

  leftrightnum = function (params) {
    return {
      fontSize: this.state.imageheight / 6,padding:0
    };
  };

  play(param) {
    this.setState({alertstatus:false})
    if (param == 1) {
      if (this.state.playVideo) {
        this.setState({ playpause: true });
        // alert("here");
      } else {
        // alert("there");
        this.setState({ playVideo: true, playpause: true });
      }
    } else if (param == 2) {
      this.setState({ playpause: false });
    } else if (param == 3) {
      this.setState({ playVideo: false });
    }
  }
  showCard() {
    if (this.state.loading) {
      return (
        <View style={styles.container}>
          <Loading size={"large"} />
        </View>
      );
    } else {
      return (
        <Card
          test={this.state.test}
          allnumbers={this.state.allnumbers}
          bingocardback={bingocardback}
          card={this.state.card}
          gameno={this.state.gameno}
        />
      );
    }
  }
  getCard(number) {
    this.setState({ loading: true });
    const headers = {
      Authorization: "Bearer " + this.props.jwt,
    };
    axios({
      method: "GET",
      url:
        "http://tv.bingo:49156/api/player/get-card?num=" +
        number +
        "&user=" +
        this.state.user.id,
      headers: headers,
    })
      .then((response) => {
        // console.log(response);
        this.setState({
          card: response.data.card,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showVideoComponent() {
    if (this.state.playVideo) {
      return (
        <VideoCon
          setPlayVideo={this.setPlayVideo}
          url={this.state.videourl}
          playpause={this.state.playpause}
        />
      );
    } else {
      return;
    }
  }

  showWinCard() {
    if (this.state.winCard) {
      return (
        <WinCardCon
          pattern={this.state.pattern}
          // setPlayVideo={this.setPlayVideo}
          // url={this.state.videourl}
          // playpause={this.state.playpause}
        />
      );
    } else {
      return;
    }
  }
  async callBingo() {
    const soundObject = new Audio.Sound();
    try {
      await soundObject.loadAsync(require("../../assets/sounds/callbingo.mp3"));
      await soundObject.playAsync();
    } catch {}

    this.socket.emit("callbingo", {
      channel: this.state.channel,
      user: this.state.user,
      cardnumber: this.state.card[12].value,
    });

    this.setState({ calldis: true});
    setTimeout(this.setCallDis, 10000);
  }
  setCallDis() {
    this.setState({ calldis: false });
  }
  showImageComponent() {
    if (this.state.showImage) {
      return <ImageCon url={this.state.imageurl} />;
    } else {
      return;
    }
  }
  showAll() {
    const showallcontent = no75.map((ele, index) => {
      let st = null;
      if (this.state.allnumbers.includes(ele)) {
        st = styles.highlightTile;
      }
      return (
        <View key={"tile" + index} style={[styles.tile, st]}>
          <Text style={{fontSize:25}}>{ele}</Text>
        </View>
      );
    });
    if (this.state.isshowall) {
      return (
        <View
          style={{
            backgroundColor: bingocardback,
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.setState({ isshowall: false });
            }}
          >
            <View
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                height: "6.25%",
              }}
            >
              <View style={styles.tileTitle}>
                <Text style={styles.tileTitletext}>B</Text>
              </View>
              <View style={styles.tileTitle}>
                <Text style={styles.tileTitletext}>I</Text>
              </View>
              <View style={styles.tileTitle}>
                <Text style={styles.tileTitletext}>N</Text>
              </View>
              <View style={styles.tileTitle}>
                <Text style={styles.tileTitletext}>G</Text>
              </View>
              <View style={styles.tileTitle}>
                <Text style={styles.tileTitletext}>O</Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                height: "90%",
              }}
            >
              {showallcontent}
            </View>
            <View
              style={{
                display:'flex',
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#B41487",
                height: "3.75%",
              }}
            >
              <Text style={{color:'white',fontSize:20}}>Touch anywhere to hide this window</Text>
            </View>
          </TouchableOpacity>
        </View>
      );
    }
  }

  async playerInfo() {
    this.setState({ playerinfostatus: true });
  }
  find_dimesions(layout) {
    const { x, y, width, height } = layout;
    console.warn(x);
    console.warn(y);
    console.warn(width);
    console.log(height);
    this.setState({ imageheight: height });
  }
  render() {
    const { numbersdrawn, lastdrawn, drawnumber, gameno,gamenames } = this.state;
    // console.log(this.state.imageurl);

    const ratio = screenHeight / screenWidth;
    let cardwidth = 0;
    if (ratio > 1.5) {
      cardwidth = screenWidth;
    } else {
      cardwidth = screenWidth * 0.8;
    }
    return (
      <Fragment>
        {this.showImageComponent()}
        {this.showVideoComponent()}
        {this.showWinCard()}
        <View style={{ flex: 1 }}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View
              onLayout={(event) => {
                this.find_dimesions(event.nativeEvent.layout);
              }}
              style={{
                width: "100%",
                position: "absolute",
                height: "100%",
              }}
            >
              {/* 

             <Image  source={require("../../assets/img/banner-r.png")}
              style={{resizeMode:'contain',position:'absolute',height:'100%'}}
             
            /> */}
              <View
                style={{
                  // borderWidth: 1,
                  width: "100%",
                  height: "100%",
                  // borderColor: "red",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <Image
                  height={this.state.imageheight}
                  source={require("../../assets/img/left.png")}
                />
              </View>
              <View
                style={{
                  // borderWidth: 1,
                  width: "100%",
                  height: "100%",
                  // borderColor: "blue",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <Image
                  height={this.state.imageheight}
                  source={require("../../assets/img/right.png")}
                  style={{ right: 0, position: "absolute" }}
                />
              </View>
            </View>

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Grid>
                <Col>
                  <View
                    style={{
                      isplay: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={this.leftrightnum()}>{lastdrawn}</Text>
                    <Text>Last #</Text>
                  </View>
                </Col>

                <Col>
                  <Text style={this.drawnum()}>{drawnumber}</Text>
                  <Text style={{ textAlign: "center" }}>Game #{gameno} {gamenames[gameno-1]}</Text>
                </Col>
                <Col>
                  <View
                    style={{
                      isplay: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={this.leftrightnum()}>{numbersdrawn}</Text>
                    <Text>Total</Text>
                  </View>
                </Col>
              </Grid>
            </View>

            {/* </ImageBackground> */}
          </View>
        </View>

        <View style={this.footerStyle()}>
          <Button
            onPress={() => {
              this.setState({ isshowall: true });
            }}
            style={styles.middlebarBtn}
            vertical
            active
          >
            {/* <Icon name="apps" />  */}
            <Image
              style={styles.icon}
              source={require("../../assets/img/weekly-calendar.png")}
            />
            <Text style={styles.tabtitle}>Show All</Text>
          </Button>

          <Button
            disabled={this.state.calldis ? true : false}
            onPress={this.callBingo}
            style={styles.middlebarBtn}
            vertical
            active
          >
            <Image
              style={styles.icon}
              source={this.state.calldis?require("../../assets/img/gray.png"):require("../../assets/img/phone-receiver.png")}
            />
            <Text style={[styles.tabtitle,this.state.calldis?styles.gray:styles.tabtitle]}>Call Bingo</Text>
          </Button>
          <Button
            onPress={this.playerInfo}
            style={styles.middlebarBtn}
            vertical
            active
          >
            <Image
              style={styles.icon}
              source={require("../../assets/img/male-reporter.png")}
            />
            <Text style={styles.tabtitle}>Player Info</Text>
          </Button>

          <Button style={styles.middlebarBtn} vertical active>
            <Image
              style={styles.icon}
              source={require("../../assets/img/online.png")}
            />
            <Text style={styles.tabtitle}>Online-{this.state.onlinecount}</Text>
          </Button>
        </View>
        <View style={{ alignItems: "center" }}>
          <View
            style={{
              aspectRatio: 0.84,
              width: cardwidth - 20,
              backgroundColor: bingocardback,

              margin: 10,
              borderWidth: 3,
              borderRadius: 5,
              borderColor: bingocardback,
            }}
          >
            <ImageBackground
              source={require("../../assets/img/backtrans.png")}
              style={styles.cardback}
            >
            <ImageBackground
              source={{uri: "http://test.tv.bingo/server/files/chicago/gamebackgrounds/"+ gameno +".png"}}
              style={styles.cardback}
            >
              {this.showCard()}
            </ImageBackground>
            </ImageBackground>
          </View>
        </View>
        <Alert
          msg={this.state.alert}
          show={this.state.alertstatus}
          setAlertStatus={this.setAlertStatus}
          videoon={this.state.playVideo}
        />
        <Playerinfo
          name={this.state.user.firstname + " " + this.state.user.lastname}
          email={this.state.user.email}
          phone={this.state.user.phone}
          address={
            this.state.user.street +
            ", " +
            this.state.user.city +
            ", " +
            this.state.user.state +
            ", " +
            this.state.user.zip
          }
          show={this.state.playerinfostatus}
          setPlayerInfoStatus={this.setPlayerInfoStatus}
        />
        {this.showAll()}
      </Fragment>
    );
  }
}

const styles = {
  gray:{
    color:'#ddd'
  },
  tile: {
    display: "flex",
    margin:0,
    padding:0,
    borderColor: bingocardback,
    borderWidth: 1,
    height:  "6.66%",
    width: "20%",
    backgroundColor: "white",

    alignItems: "center",
    justifyContent: "center",
  },
  tileTitle: {
    borderWidth: 1,
    borderColor: bingocardback,
    height: "100%",
    width: "20%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },

  highlightTile: {
    backgroundColor: "#FB8C00",
    color:'white'
  },
  icon: {
    flex: 1,
    // width: null,
    // height: null,
    resizeMode: "contain",
  },

  banner: {
    position: "absolute",
    height: "100%",
    width: 300,
    // flex: 1,
    flexShrink: 1,
    resizeMode: "contain",

    // justifyContent: "center",
    // alignSelf: "flex-end"
  },
  cardback: {
    resizeMode: "cover",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    height: "100%",
  },

  container: {
    // flex: 1,
    width: "100%",
    height: "100%",
    // backgroundColor:"red"
  },
  // backgroundColor: "red",
  // position:'absolute',
  // top:200,
  flex: 1,
  // width:'100%'
  resizeMode: "cover",

  center: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  left: {
    flex: 1,
    justifyContent: "center",
  },
  right: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
  },

  background: {
    flex: 1,
    resizeMode: "contain", // or 'stretch'
  },
  tabtitle: {
    color: "white",
    textShadowColor: "black",
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 5,
    fontSize: 15,
  },
  footer: {
    backgroundColor: "",
    flexDirection: "row",
    justifyContent: "space-around",

    height: 50,
  },
  middlebarBtn: {
    height: 60,
    backgroundColor: "transparent",
    borderWidth: 0,
    shadowOpacity: 0,
    elevation: 0,
    flex: 1,
    // flexDirection:'row'
  },
  tileTitletext:{
    fontSize:25
  }
};

export { Main };
