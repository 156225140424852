import React from "react";
import { View, ActivityIndicator, TouchableOpacity } from "react-native";
import Dialog, { DialogContent, DialogTitle } from "react-native-popup-dialog";
import { Text, Grid, Col, Row, Button } from "native-base";

const Playerinfo = ({
  name,
  email,
  phone,
  address,
  dob,
  show,
  setPlayerInfoStatus,
}) => {
  return (
    <Dialog
      width="90%"
      visible={show}
      onTouchOutside={() => {
        setPlayerInfoStatus();
      }}
      dialogTitle={<DialogTitle 
        
        textStyle={styles.pophead}
        style={styles.header} title="Player Info" />}
    >
      <DialogContent >
        <View style={styles.row_}>
          <Text style={styles.title}>Name</Text>
          <Text>: </Text>
          <Text style={styles.data}>{name}</Text>
        </View>
        <View style={styles.row_}>
          <Text style={styles.title}>Email</Text>
          <Text>: </Text>
          <Text style={styles.data}>{email}</Text>
        </View>
        <View style={styles.row_}>
          <Text style={styles.title}>Phone</Text>
          <Text>: </Text>
          <Text style={styles.data}>{phone}</Text>
        </View>
        <View style={styles.row_}>
          <Text style={styles.title}>Address</Text>
          <Text>: </Text>
          <Text style={styles.data}>{address} defb  herh erh reh e</Text>
        </View>

        <Button block style={styles.button} onPress={() => {
        setPlayerInfoStatus();
      }}>
      <Text style={styles.buttonText}>Hide</Text>
    </Button>
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  pophead:{color:'white',fontSize:30},
  title:{
    // backgroundColor:'green'    ,
    width:'30%',
    fontSize:25
  },
  data:{
    // backgroundColor:'red',
    width:'70%',
    fontSize:25
  },
  row_: {
    display:'flex',
    flexDirection: "row",
    paddingBottom:10
    // width:'100%'
    
  },
  header:{
    backgroundColor:'#B41487',
    color:'white'
  },
  buttonText: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
    fontSize: 20,
  },
  button: {
    marginTop: 20,
    backgroundColor: "#B41487",
    // borderColorLwhite:'white'
  },
};
export { Playerinfo };
