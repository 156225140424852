import React, { Component } from "react";
import { View } from "react-native";
import { Login, Registration, ForgotPass } from "../components";

import { Container } from "native-base";

export default class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: true,
      showFogotPass: false,
    };
    this.whichForm = this.whichForm.bind(this);
    this.authSwitch = this.authSwitch.bind(this);
    this.authSwitchForgot = this.authSwitchForgot.bind(this);
  }

  authSwitch() {
    this.setState({
      showLogin: !this.state.showLogin,
    });
  }
  authSwitchForgot() {
    this.setState({
      showFogotPass: !this.state.showFogotPass,
    });
  }

  whichForm() {
  
    if (!this.state.showLogin) {
      return (
        <Registration newJWT={this.props.newJWT} authSwitch={this.authSwitch} />
      );
    } else {
      if (!this.state.showFogotPass) {
        return (
          <Login
            authSwitchForgot={this.authSwitchForgot}
            newJWT={this.props.newJWT}
            authSwitch={this.authSwitch}
          />
        );
      } else {
        return <ForgotPass authSwitchForgot={this.authSwitchForgot} />;
      }
    }
  }

  render() {
    return <Container style={styles.container}>{this.whichForm()}</Container>;
  }
}

const styles = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
};
