import React, { Component, Fragment } from "react";
// import { Text, View } from "native-base";
import {View, Image, ImageBackground } from "react-native";
import { Text } from "native-base";


// import { Video } from "expo-av";

class ImageCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url:this.props.url
    
    };
  }

  componentWillUnmount() {}
  componentDidMount() {}

  render() {
    return (
      <View style={styles.container}>
      <Image source={{ uri: this.props.url }} style={styles.image}/>
       
    
    </View>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    flexDirection: "column",
    position:'absolute',
    zIndex:1000,
    width:'100%',
    backgroundColor:'black',
    height:'100%'
  },
  image: {
    flex: 1,
    resizeMode: "contain",
    justifyContent: "center"
  },
 
};

export { ImageCon };
