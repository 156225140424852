import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TextInput,
  TouchableOpacity,
  AsyncStorage,
  ImageBackground,
  Linking,
  ScrollView
} from "react-native";
import { Loading, TextLink } from "./common";
// import { Container, Text } from 'native-base';
import deviceStorage from "../services/deviceStorage";
import axios from "axios";
import { Item, Label, Input, Button } from "native-base";
import Modal from "react-native-modal";
import WebView from "react-native-webview";
const io = require("socket.io-client");
const SocketEndpoint = "http://tv.bingo:49156";
class Login extends Component {
  socket = io(SocketEndpoint, {
    transports: ["websocket"],
  });
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      loading: false,
      visible:false,
      rules:[]
    };
    this.logiUser = this.loginUser.bind(this);
  }
  componentDidMount(){
     axios({
      method: "GET",
      url: "http://tv.bingo:49156/api/player/get-rules",
      // headers: headers,
    })
      .then((response) => {
        // console.log(response);
        this.setState({
          rules: response.data.rules,
         
        });
   
      })
      .catch((error) => {
     
        // console.log(error)
        // this.setState({
        //   error: "Error retrieving data",
        //   loading: false,
        // });
      });
  }
  componentWillUnmount() {
    this.socket.disconnect();
  }
  loginUser() {
    const { email, password, password_confirmation } = this.state;

    this.setState({ error: "", loading: true });

    // NOTE Post to HTTPS only in production
    axios
      .post("http://tv.bingo:49156/api/player/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        this.setState({ error: response.data.msg });
        deviceStorage.saveKey("user", JSON.stringify(response.data.user));

        deviceStorage.saveKey("id_token", response.data.token);

        this.props.newJWT(response.data.token);
      })
      .catch((error) => {
        console.log(error.response.data);

        this.setState({ error: error.response.data.msg, loading: false });
      });
  }


  _renderButton = (text, onPress) => (
    <Button block style={styles.hidebutton} onPress={onPress}>
      <Text style={styles.hidebuttonText}>{text}</Text>
    </Button>
  );
  _renderModalContent = () => {



    const listItems = this.state.rules.map((rule) => {
     
   
       
        return (
          <View style={{display:'flex',flexDirection:'row'}}>
          <Text>{'\u2B24'} </Text>
        <Text style={styles.rule}>{rule.description}</Text></View>
        );
      
    });

  return ( <View style={styles.modalContent}>
     <ScrollView> 


       {listItems}
     
    </ScrollView>



    
      {this._renderButton("Hide", () => {
      
          this.setState({ visible: false });
      
      })}
    </View>)
  };
  render() {
    const { email, password, error, loading } = this.state;
    const { form, section, errorTextStyle, container } = styles;

    return (
      <View style={styles.container}>
          <Modal isVisible={this.state.visible}
          //  onBackdropPress={() => this.setState({visible: false})}
           >
          
       
            {this._renderModalContent()}
          </Modal>


        <ImageBackground
          resizeMode="cover"
          style={styles.img_back}
          source={require("../../assets/img/login-back.png")}
        >
          <View style={styles.logoContainer}>
            {/* <Image
            style={styles.logo}
            source={require("../../assets/img/login.png")}
          /> */}
            <Text style={styles.title}>Brought to you by the makers of</Text>
            <TouchableOpacity
                onPress={() => {
                  Linking.openURL("https://www.facebook.com/freenfunbarbingo");
                }}
              >
                   <Text style={styles.link}>FreeN' Fun BarBingo</Text>
              </TouchableOpacity>
              <Text style={styles.title}>Apple and Google are NOT responsible nor liable in any way for sweepstakes held within</Text>
            
          </View>
          <View style={styles.formContainer}>
            {/* <TextInput style={styles.input}
            placeholder="Email"
            placeholderTextColor="rgba(255,255,255,.7)"
            value={email}
            onChangeText={email => this.setState({ email })}
          /> */}
            <Item style={styles.inpt} floatingLabel>
              <Label style={styles.lbl}>Email</Label>
              <Input
                style={styles.in}
                value={email}
                onChangeText={(email) => this.setState({ email })}
              />
            </Item>

            <Item style={styles.inpt} floatingLabel>
              <Label style={styles.lbl}>Password</Label>
              <Input
                style={styles.in}
                value={email}
                onChangeText={(email) => this.setState({ email })}
                secureTextEntry
                value={password}
                onChangeText={(password) => this.setState({ password })}
              />
            </Item>

            {/* <TextInput style={styles.input}
            placeholder="Pasword"
            placeholderTextColor="rgba(255,255,255,.7)"
          secureTextEntry
          value={password}
              onChangeText={password => this.setState({ password })}
          /> */}

            <Text style={errorTextStyle}>{error}</Text>
            {!loading ? (
              //  <TouchableOpacity  style={styles.buttonContainer}>
              <Button block onPress={this.logiUser} style={styles.button}>
                <Text style={styles.buttonText}>Login</Text>
              </Button>
            ) : (
              //  </TouchableOpacity>
              <Loading size={"large"} />
            )}
            <TextLink onPress={this.props.authSwitch}>
              Don't have an account? Register!
            </TextLink>
            <TextLink onPress={this.props.authSwitchForgot}>
             Forgot Password?
            </TextLink>
            <View style={styles.linkcon}>
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL("https://fnfbargame.net/schedule");
                }}
              >
                <Text style={styles.link}>Schedule</Text>
              </TouchableOpacity>
              <Text style={styles.sep}>|</Text>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ visible: true });
                }}
              >
                <Text style={styles.link}>Rules </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ImageBackground>
      </View>
    );
  }
}

const styles = {
  // form: {
  //   width: '100%',
  //   borderTopWidth: 1,
  //   borderColor: '#ddd',
  // },
  // section: {
  //   flexDirection: 'row',
  //   borderBottomWidth: 1,
  //   backgroundColor: '#fff',
  //   borderColor: '#ddd',
  // },
  errorTextStyle: {
    alignSelf: "center",

    fontSize: 18,
    color: "white",
    // marginTop:10,
    marginBottom: 10,
  },
  container: {
    flex: 1,

    // backgroundColor:'#4169e1',
    width: "100%",
    height: "100%",
  },
  logoContainer: {
    // backgroundColor:'red',
    flex: 1,
    alignItems: "center",
    // flexGrow:1,
    justifyContent: "flex-end",
  },
  img_back: {
    flex: 1,
  },
  formContainer: {
    flex: 1,
    alignItems: "center",
    // flexGrow:1,
    justifyContent: "flex-start",
    padding: 20,
  },

  logo: {
    width: 200,
    height: 200,
    // aspectRatio:1
    // aspectRatio: 1
  },
  title: {
    color: "#fff",
    marginTop: 10,
    textAlign: "center",
    // fontSize: 15,
    opacity: 0.9,
  },
  input: {
    height: 40,
    backgroundColor: "rgba(255,255,255,0.2)",
    marginBottom: 20,
    color: "#fff",
    paddingLeft: 10,
  },
  buttonContainer: {
    // backgroundColor:'#2980b9',
    // paddingVertical:15
  },
  buttonText: {
    textAlign: "center",
    color: "#B41487",
    fontWeight: "700",
    fontSize: 20,
  },
  hidebuttonText: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
    fontSize: 20,
  },
  button: {
    backgroundColor: "white",
  },
  hidebutton: {
    backgroundColor: "#B41487",
  },
  inpt: {
    borderColor: "white",
    marginBottom: 10,
    color: "white",
  },
  lbl: {
    color: "white",
  },
  in: {
    color: "white",
  },
  link: {
    alignSelf: "center",
    color: "white",
    fontSize: 18,
    // fontWeight: '700',
    textDecorationLine: "underline",
  },
  sep: {
    color: "white",
    fontSize: 18,
    paddingLeft: 10,
    paddingRight: 10,
  },
  linkcon: {
    marginTop:20,
    flex: 1,
    flexDirection: "row",
  },
  modalContent: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  rule:{
    paddingBottom:10
  }
};

export { Login };
