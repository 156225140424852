import React, { Fragment } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { ListItem, Left, Thumbnail, Body } from "native-base";

const Hostitem = ({ nickname, status, method, color,avatar }) => {
  
  return (
    // <View style={styles.listitem}>
    //     <TouchableOpacity onPress={method}>
    //   <Text >{nickname}</Text>
    //   <Text >{status}</Text>
    //   </TouchableOpacity>
    // </View>

    <Fragment>
      <TouchableOpacity>
        <ListItem style={styles.li} button onPress={method}>
          <View>
            <Thumbnail
              round
              source={{ uri: avatar }}
            />

          </View>
          <View style={styles.body}>
            <Text style={styles.nickname}>{nickname}</Text>
            <View>
              <Text style={{
                color: color, fontStyle: 'italic',
              
                flexWrap: 'wrap'
              }} > 
               {status}
              </Text>
            </View>


          </View>
        </ListItem>
      </TouchableOpacity>
    </Fragment>
  );
};

const styles = {
  body: {
    marginLeft: 20,
    marginRight: 20


  },
  nickname: {
    fontSize: 20,
    color: '#B41487',
    fontWeight: '700'
  }
  ,
  li: {
    borderColor: '#B41487',
    marginRight:20
  },
 
};
export { Hostitem };
