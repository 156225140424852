import Expo from 'expo';
import React,{Component} from 'react';
import {Loading} from './src/components/common';
import Auth from './src/screens/Auth';
import LoggedIn from './src/screens/LoggedIn';
import deviceStorage from './src/services/deviceStorage';
import { StyleSheet, Text, View } from 'react-native';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';


export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      jwt: '',
      loading:true
    }
    this.newJWT = this.newJWT.bind(this);
    this.deleteJWT = deviceStorage.deleteJWT.bind(this);
    this.loadJWT = deviceStorage.loadJWT.bind(this);
    this.loadJWT();
  }
  newJWT(jwt){
    this.setState({
      jwt: jwt
    });
  }  
  // state = {
  //   isConnected: false,
  //   data: null,
  // };
  async componentDidMount() {
    await Font.loadAsync({
      Roboto: require('native-base/Fonts/Roboto.ttf'),
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      ...Ionicons.font,
    });
    
  }

  render() {
    if (this.state.loading) {
      return (
        <Loading size={'large'} />
       );
    } else if (!this.state.jwt) {
      return (
        <Auth newJWT={this.newJWT} />
      );
    } else if (this.state.jwt) {
      return (
        <LoggedIn jwt={this.state.jwt} deleteJWT={this.deleteJWT} />
      );
    }
  }

}
