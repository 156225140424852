import React, { Component, Fragment } from "react";

import { Video } from "expo-av";

class VideoCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  
  }

  componentWillUnmount() {}
  componentDidMount() {}

 
  _onPlaybackStatusUpdate = (playbackStatus) => {
    if (playbackStatus.didJustFinish) {
      // alert("ended");
      this.props.setPlayVideo(false);
    }
   
  };
  render() {
    const { email, password, error, loading, mainNo } = this.state;
    const { form, section, errorTextStyle, container } = styles;

    return (
      <Video
        ref={(r) => (this.vid = r)}
        source={{ uri: this.props.url }}
        rate={1.0}
        volume={1.0}
        muted={false}
        resizeMode="contain"
        shouldPlay={this.props.playpause}
       
        onPlaybackStatusUpdate={(playbackStatus) =>
          this._onPlaybackStatusUpdate(playbackStatus)
        }
        style={styles.video}
      />
    );
  }
}

const styles = {
  video: {
    width: "100%",
    height:"100%",
    backgroundColor: "black",
    position:'absolute',
    zIndex:1000000000000
  },
};

export { VideoCon };
